import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Box, Link, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "80px 0 50px 0",
	"background": "--color-darkL2",
	"quarkly-title": "Footer-17"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"lg-width": "100%",
			"flex-direction": "row",
			"lg-flex-direction": "column",
			"justify-content": "space-between",
			"width": "100%",
			"padding": "0 0px 0 0px",
			"sm-flex-direction": "column",
			"sm-padding": "0 0px 0 0px"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"lg-margin": "0px 0px 0px 0px",
			"width": "50%",
			"display": "flex",
			"lg-width": "70%",
			"sm-width": "100%",
			"flex-direction": "column",
			"padding": "0px 50px 0px 0px",
			"lg-padding": "0px 0 0px 0px",
			"sm-margin": "0px 0px 35px 0px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"font": "normal 500 18px/1.5 --fontFamily-sans",
			"color": "--light",
			"letter-spacing": "1px",
			"children": "Aernaburnit Karaoke Bar"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0 0px 35px 0px",
			"font": "--base",
			"color": "#c3c8d0",
			"sm-text-align": "left",
			"sm-margin": "0 0px 0 0px",
			"max-width": "440px",
			"children": "Aernaburnit Karaoke Bar: A chance to be a legend in your own song every night!"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"grid-template-columns": "repeat(2, 1fr)",
			"grid-gap": "54px",
			"lg-align-items": "start",
			"md-grid-template-columns": "repeat(3, 1fr)",
			"md-grid-gap": "36px 34px",
			"sm-grid-template-columns": "1fr",
			"sm-grid-gap": "16px 0",
			"justify-content": "flex-end",
			"sm-flex-direction": "column",
			"display": "flex",
			"lg-justify-content": "flex-start",
			"lg-display": "flex"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"align-items": "flex-start",
			"margin": "0px 0px 0px 0",
			"lg-align-items": "flex-start",
			"justify-content": "flex-start",
			"display": "grid",
			"lg-flex-direction": "column",
			"lg-margin": "0px 0px 0px 0px",
			"flex-direction": "column",
			"align-content": "start",
			"grid-gap": "10px 0"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"border-color": "--color-primary",
			"display": "flex",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"margin": "0px 0 0px 0",
			"lg-border-width": "0px 0px 0px 2px",
			"href": "/index",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"hover-color": "#ffffff",
			"letter-spacing": "1px",
			"children": "Home"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"margin": "0px 0 0px 0",
			"display": "flex",
			"href": "/menu",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"hover-color": "#ffffff",
			"letter-spacing": "1px",
			"children": "Menu"
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"margin": "0px 0 0px 0",
			"hover-color": "#ffffff",
			"href": "/contacts",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"text-decoration-line": "initial",
			"color": "#c3c8d0",
			"display": "flex",
			"letter-spacing": "1px",
			"children": "Contact Us"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"justify-content": "space-between",
			"padding": "50px 0px 0px 0px",
			"border-color": "#232a44",
			"md-padding": "30px 0px 0px 0px",
			"md-flex-direction": "column",
			"lg-padding": "40px 0px 0px 0px",
			"align-items": "flex-end",
			"height": "auto",
			"md-align-items": "flex-start"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--base",
			"color": "#c3c8d0",
			"md-margin": "0px 0px 25px 0px",
			"children": "© 2023 All rights reserved."
		}
	}
};

const Footer = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" justify-content="flex-start" />
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Text {...override("text")} />
				<Text {...override("text1")} />
			</Box>
			<Box {...override("box2")}>
				<Box {...override("box3")}>
					<Link {...override("link")} />
					<Link {...override("link1")} />
					<Link {...override("link2")} />
				</Box>
			</Box>
		</Box>
		<Box {...override("box4")}>
			<Text {...override("text2")} />
		</Box>
		{children}
	</Section>;
};

Object.assign(Footer, { ...Section,
	defaultProps,
	overrides
});
export default Footer;